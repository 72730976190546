'use strict';

import './resources.management.less'
import './add-simple-resource-modal/add.simple.resource.modal'
import './items-assigner/items.assigner.directive'
import './resource-carousel/resource.carousel.directive'

(function () {
    angular.module('easybizy.resources.management').controller('ResourcesManagementController',
        function ($scope, Repository, $modal, localize, stateManager, employeesCrudFactory, toolbar, confirmService, configuration) {
            toolbar.set([]);
            stateManager.registerStateChanged('ResourcesManagement', stateChanged);

            $scope.$watch('selectedResourceViewWrapper.selected', function (newVal) {
                if (newVal) {
                    stateManager.setState('ResourcesManagement', {
                        viewType: newVal.viewType
                    }, false, false);
                }
            });

            // Handle URL changes and persisting state according to tabs.
            function stateChanged(state) {
                var currentResourceType = $scope.resourceTypes.filter(function (resourceType) {
                    return resourceType.viewType === state.viewType
                })[0];

                if (currentResourceType && $scope.selectedResourceViewWrapper.selected != currentResourceType) {
                    $scope.$evalAsync(function () {
                        $scope.selectedResourceViewWrapper.selected = currentResourceType
                    })
                }
            }

            function Resource() {
                this.selectedItemsChanged = (function (updatedSelection) {
                    var that = this;
                    var selectedType = this.selectedType;
                    var entityIdToUpdate = selectedType.value;
                    var idsToSend = [];
                    updatedSelection.forEach(function (category) {
                        category.items.forEach(function (item) {
                            idsToSend.push(item.value[that.entityId])
                        })
                    });

                    Repository.Custom('ResourceManagement').update(this.resourceType, entityIdToUpdate, idsToSend).catch(function (/*err*/) {
                        toastr.error(localize.getLocalizedString('_ErrorSavingResource_'))
                    });
                }).bind(this);
            }

            Resource.prototype = {
                // Selected Type
                get selectedType() {
                    return this._selectedType;
                },
                set selectedType(newValue) {
                    this._selectedType = newValue;
                    this.generateItems();
                },
                set itemsDelegate(newValue) {
                    this._delegate = newValue;
                    this.generateItems();
                },
                get itemsByCategories() {
                    var selectedType = this.selectedType;
                    return selectedType ? selectedType.itemsByCategories : null;
                },
                get selectedItemsByCategories() {
                    var selectedType = this.selectedType;
                    return selectedType ? selectedType.selectedItemsByCategories : null;
                },
                get resources() {
                    return this._resources;
                },
                set resources(newValue) {
                    this._resources = newValue;
                    if (this._resources.length > 0) {
                        this.selectedType = this._resources[0];
                    }
                }
            };

            Resource.prototype.generateItems = function () {
                if (!this.selectedType || !this._delegate || this.selectedType.itemsByCategories) {
                    return;
                }

                this._delegate(this.selectedType);
                // Force redraw.
                this.selectedType = this.selectedType;
            };

            var constantPreHeaderForSelectedServices = localize.getLocalizedString('_ServicesSelectedFor_');
            var constantPreHeaderForEmployeeAssignment = localize.getLocalizedString('_EmployeeTypesFor_');

            $scope.employeeTypeResource = new Resource;
            Object.assign($scope.employeeTypeResource,
                {
                    viewType: 'employee-types',
                    resourceType: 'employeeType',
                    entityId: 'ServiceMetadataId',
                    name: localize.getLocalizedString('_EmployeeTypes_'),
                    itemsHeader: localize.getLocalizedString('_ServicesAvailable_'),
                    selectedItemsHeader: function () {
                        return '<span>' + constantPreHeaderForSelectedServices + '<b>' + ((this.selectedType && this.selectedType.name) || '') + '</b></span>';
                    },
                    emptyPlaceholder: localize.getLocalizedString('_AddEmployeeTypePlaceholder_'),
                    emptyResourcePlaceholder: localize.getLocalizedString('_CreateAnEmployeeTypeFirst_'),
                    addResourceDelegate: addOrEditResource.bind($scope.employeeTypeResource),
                    editResourceDelegate: addOrEditResource.bind($scope.employeeTypeResource),
                    deleteResourceDelegate: deleteResource.bind($scope.employeeTypeResource),
                    resourceChangedDelegate: function () {
                        clearEmployeeAssignments();
                    }
                });


            $scope.employeeAssignmentResource = new Resource;
            Object.assign($scope.employeeAssignmentResource,
                {
                    viewType: 'employee-assignments',
                    resourceType: 'employee',
                    entityId: 'value', // This is because the id is taken from the actual EmployeeTypesResource
                    name: localize.getLocalizedString('_EmployeeAssignment_'),
                    itemsHeader: localize.getLocalizedString('_AvailableEmployeeTypes_'),
                    selectedItemsHeader: function () {
                        return '<span>' + constantPreHeaderForEmployeeAssignment + '<b>' +
                            ((this.selectedType && this.selectedType.name) || '') + '</b></span>';
                    },
                    emptyPlaceholder: localize.getLocalizedString('_AddEmployee_'),
                    emptyResourcePlaceholder: localize.getLocalizedString('_CreateAnEmployeeTypeFirst_'),
                    addResourceDelegate: addOrEditResource.bind($scope.employeeAssignmentResource),
                });

            getEmployeeTypes();
            getServices();
            getEmployees();


            $scope.resourceTypes = [
                $scope.employeeTypeResource,
                $scope.employeeAssignmentResource
            ];

            if (configuration.get().CalendarSettings.EnableRooms) {
                $scope.roomsResource = new Resource;
                Object.assign($scope.roomsResource,
                    {
                        viewType: 'rooms',
                        resourceType: 'room',
                        entityId: 'ServiceMetadataId',
                        name: localize.getLocalizedString('_Rooms_'),
                        itemsHeader: localize.getLocalizedString('_ServicesAvailable_'),
                        selectedItemsHeader: function () {
                            return '<span>' + constantPreHeaderForSelectedServices + '<b>' + ((this.selectedType && this.selectedType.name) || '') + '</b></span>';
                        },
                        emptyPlaceholder: localize.getLocalizedString('_AddRoomPlaceholder_'),
                        emptyResourcePlaceholder: localize.getLocalizedString('_CreateARoomFirst_'),
                        addResourceDelegate: addOrEditResource.bind($scope.roomsResource),
                        editResourceDelegate: addOrEditResource.bind($scope.roomsResource),
                        deleteResourceDelegate: deleteResource.bind($scope.roomsResource),
                        theme: 'second'
                    });

                getRooms();
                $scope.resourceTypes.splice(1, 0, $scope.roomsResource);
            }

            $scope.selectedResourceViewWrapper = {};

            function clearEmployeeAssignments() {
                $scope.employeeAssignmentResource.resources.forEach(function (employeeTypeWrapper) {
                    delete employeeTypeWrapper.itemsByCategories;
                    delete employeeTypeWrapper.selectedItemsByCategories;
                });

                // Force reselecting items.
                var currentSelection = $scope.employeeAssignmentResource.selectedType;
                $scope.employeeAssignmentResource.selectedType = currentSelection;
            }

            function getEmployeeTypes() {
                Repository.Entity("EmployeeType").query().expand("ServiceMetadatas")
                    .get()
                    .then(function (data) {
                        $scope.employeeTypeResource.resources = data.results.map(employeeTypeToEmployeeTypeItem);
                        $scope.employeeAssignmentResource.itemsDelegate = generateEmployeeTypesForEmployeesContainer;
                    });
            }

            function getRooms() {
                Repository.Entity("Room").query().expand("ServiceMetadatas")
                    .get()
                    .then(function (data) {
                        $scope.roomsResource.resources = data.results.map(roomToRoomItem);
                    });
            }

            function getServices() {
                Repository.Entity("ServiceMetadata").query().expand("Images,ServiceCategories")
                    .get()
                    .then(function (data) {
                        $scope.employeeTypeResource.itemsDelegate = generateServicesMetadataForContainers.bind(null, data.results);

                        if ($scope.roomsResource) {
                            $scope.roomsResource.itemsDelegate = generateServicesMetadataForContainers.bind(null, data.results);
                        }
                    });
            }

            function getEmployees() {
                Repository.Entity("Employee").query().expand('EmployeeTypes').get()
                    .then(function (data) {
                        $scope.employeeAssignmentResource.resources = data.results.map(employeeToEmployeeItem);
                    })
            }

            function roomToRoomItem(room) {
                return {
                    name: room.Name,
                    color: room.Color,
                    maxQuantity: room.MaxQuantity ?? 1,
                    value: room.RoomId,
                    preSelectedIds: !room.ServiceMetadatas ? [] : room.ServiceMetadatas.map(function (serviceMetadata) {
                        return serviceMetadata.ServiceMetadataId
                    })
                };
            }

            function employeeTypeToEmployeeTypeItem(employeeType) {
                return {
                    name: employeeType.EmployeeTypeName,
                    color: employeeType.Color,
                    value: employeeType.EmployeeTypeId,
                    preSelectedIds: !employeeType.ServiceMetadatas ? [] : employeeType.ServiceMetadatas.map(function (serviceMetadata) {
                        return serviceMetadata.ServiceMetadataId
                    })
                }
            }

            function employeeToEmployeeItem(employee) {
                return {
                    name: employee.FirstName + ' ' + employee.LastName,
                    color: '#7d7f82', // fixate. Employee Doesn't have a color...
                    value: employee.EmployeeId,
                    preSelectedIds: !employee.EmployeeTypes ? [] : employee.EmployeeTypes.map(function (employeeType) {
                        return employeeType.EmployeeTypeId
                    })
                }
            }


            function addOrEditResource(entity) {
                var that = this;
                switch (this.resourceType) {
                    case 'employeeType':
                        openSimpleResourceDialog(localize.getLocalizedString('_AddPlaceholder_', localize.getLocalizedString('_EmployeeType_')),
                            localize.getLocalizedString('_EmployeeType_'), function createEmployeeType(employeeTypeMeta) {
                                var entityToSend = {
                                    EmployeeTypeName: employeeTypeMeta.name,
                                    Color: employeeTypeMeta.color
                                };

                                if (entity) {
                                    entityToSend.EmployeeTypeId = entity.value;
                                }

                                return Repository.Entity('EmployeeType')[entity ? 'update' : 'create'](entityToSend)
                                    .post()
                                    .then(function (result) {
                                        $scope.$evalAsync(function () {
                                            if (!entity) {
                                                var toAdd = employeeTypeToEmployeeTypeItem(result);
                                                that.resources.push(toAdd);
                                                that.selectedType = toAdd;
                                                if (that.resourceChangedDelegate) {
                                                    that.resourceChangedDelegate();
                                                }
                                            } else {
                                                entity.name = employeeTypeMeta.name;
                                                entity.color = employeeTypeMeta.color;
                                            }
                                        });
                                    });
                            }, entity, false);
                        break;
                    case 'room':
                        openSimpleResourceDialog(localize.getLocalizedString('_AddPlaceholder_', localize.getLocalizedString('_Room_')),
                            localize.getLocalizedString('_RoomName_'), function createRoom(roomMeta) {

                                var entityToSend = {
                                    Name: roomMeta.name,
                                    Color: roomMeta.color,
                                    MaxQuantity: roomMeta.maxQuantity
                                };

                                if (entity) {
                                    entityToSend.RoomId = entity.value;
                                }

                                return Repository.Entity('Room')[entity ? 'update' : 'create'](entityToSend)
                                    .post()
                                    .then(function (result) {
                                        $scope.$evalAsync(function () {
                                            if (!entity) {
                                                var toAdd = roomToRoomItem(result);
                                                that.resources.push(toAdd);
                                                that.selectedType = toAdd;
                                                if (that.resourceChangedDelegate) {
                                                    that.resourceChangedDelegate();
                                                }
                                            } else {
                                                entity.name = roomMeta.name;
                                                entity.color = roomMeta.color;
                                                entity.maxQuantity = roomMeta.maxQuantity;
                                            }
                                        });

                                    });
                            }, entity, true);
                        break;

                    case 'employee':
                        openEmployeeDialog();
                        break;
                }
            }

            function deleteResource(entity) {
                var that = this;
                var entityToRemove = { entityType: this.resourceType.toTitleCase(), id: entity.value };
                return Repository.Entity(entityToRemove.entityType).remove(entityToRemove.id).then(function (data) {
                    console.log("deleted");
                    return true;
                }).catch(function (err) {
                    toastr.error(localize.getLocalizedString('_ErrorDeletingEntity_', err));
                    return false;
                });
            }

            function openSimpleResourceDialog(header, typePlaceholder, delegate, existingEntity, isRoom) {
                var opts = {
                    backdrop: 'static',
                    backdropClick: false,
                    template: require('../../partial-views/resources-management/add-simple-resource-modal/add.simple.resource.modal.html'),
                    controller: "AddSimpleResourceController",
                    resolve: {
                        header: function () {
                            return header;
                        },
                        placeholderName: function () {
                            return typePlaceholder;
                        },
                        saveAction: function () {
                            return delegate;
                        },
                        entity: function () {
                            return existingEntity;
                        },
                        isRoom: function () {
                            return isRoom;
                        }
                    }
                };

                $modal.open(opts);
            }

            function openEmployeeDialog() {
                var options = {
                    backdrop: 'static',
                    windowClass: 'transition-off',
                    keyboard: true,
                    backdropClick: false,
                    template: require('../../modal-views/add-employee/add.employee.modal.tpl.html'),
                    controller: 'AddEmployeeModalController',
                    resolve: {
                        saveAction: function () {
                            return function (newEntity, oldEntity, callback) {
                                return employeesCrudFactory.createOrUpdate(newEntity, oldEntity)
                                    .then(function (employee) {
                                        $scope.$evalAsync(function () {
                                            var newEmployee = employeeToEmployeeItem(newEntity);
                                            $scope.employeeAssignmentResource.resources.unshift(newEmployee);
                                            $scope.employeeAssignmentResource.selectedType = newEmployee;
                                        });
                                    })
                                    .catch(function (err) {
                                        toastr.error(localize.getLocalizedString('_ErrorProducing_', localize.getLocalizedString('_Employee_')));
                                    });

                            };
                        },
                        parameters: function () {
                            return {};
                        }
                    },
                };

                $modal.open(options);
            }


            function generateServicesMetadataForContainers(services, wrapperItem) {
                var servicesByCategories = wrapperItem.itemsByCategories = [];
                var selectedServicesByCategories = wrapperItem.selectedItemsByCategories = [];

                var otherCategory = [];
                var selectedOtherCategory = [];

                var servicesByCategory = {};
                var selectedServicesByCategory = {};
                services.forEach(function (service) {
                    var serviceCategory = otherCategory,
                        selectedServiceCategory = selectedOtherCategory;

                    if (service.ServiceCategories) {
                        serviceCategory = servicesByCategory[service.ServiceCategories[0].ServiceCategoryName] = servicesByCategory[service.ServiceCategories[0].ServiceCategoryName] || [];
                        selectedServiceCategory = selectedServicesByCategory[service.ServiceCategories[0].ServiceCategoryName] = selectedServicesByCategory[service.ServiceCategories[0].ServiceCategoryName] || [];
                    }

                    var adaptedService = {
                        name: service.ServiceName, value: service,
                        category: Object.assign({ name: service.ServiceCategories[0].ServiceCategoryName }, service.ServiceCategories[0]),
                        selectedContainer: selectedServiceCategory,
                        container: serviceCategory
                    };

                    if (wrapperItem.preSelectedIds.indexOf(service.ServiceMetadataId) > -1) {
                        selectedServiceCategory.push(adaptedService);
                    } else {
                        serviceCategory.push(adaptedService);
                    }

                });

                for (var categoryName in servicesByCategory) {
                    servicesByCategories.push({ name: categoryName, items: servicesByCategory[categoryName] });
                    selectedServicesByCategories.push({ name: categoryName, items: selectedServicesByCategory[categoryName] })
                }

                if (otherCategory.length > 0) {
                    servicesByCategories.push({ name: localize.getLocalizedString('_Uncategorized_'), items: otherCategory });
                }

                servicesByCategories.sort(function (a, b) {
                    return a.name > b.name;
                });

                $scope.$digestIfNeeded();
            }


            var employeeTypeGeneralCategoryName = localize.getLocalizedString('_EmployeeTypes_');

            function generateEmployeeTypesForEmployeesContainer(wrapperItem) {
                wrapperItem.itemsByCategories = [];
                wrapperItem.selectedItemsByCategories = [];

                var container = [];
                var selectedContainer = [];
                $scope.employeeTypeResource.resources.forEach(function (employeeType) {
                    var adaptedEmployeeType = {
                        name: employeeType.name, value: employeeType,
                        category: Object.assign({ name: employeeTypeGeneralCategoryName }, container),
                        container: container,
                        selectedContainer: selectedContainer
                    };

                    if (wrapperItem.preSelectedIds.indexOf(employeeType.value) > -1) {
                        selectedContainer.push(adaptedEmployeeType);
                    } else {
                        container.push(adaptedEmployeeType);
                    }
                });

                wrapperItem.itemsByCategories.push({ name: employeeTypeGeneralCategoryName, items: container });
                wrapperItem.selectedItemsByCategories.push({ name: employeeTypeGeneralCategoryName, items: selectedContainer });
            }


        });

}());
