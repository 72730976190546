import './lead.details.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.leads')
  .controller('LeadDetailsController',
    function ($scope, $stateParams, Repository,
      $window, $timeout, toolbar,
      localize, $state,
      $modal, printerMediator, $filter, entityImageResolver, configuration, colorsService, confirmService, $q) {
      toolbar.set([
        {
          name: 'add',
          action: function (timelineEntityCustomNote) {
            return addCustomLeadNote(timelineEntityCustomNote);
          },
          params: {
            getOwnerId: getLeadId,
            reminderType: 'customer'
          },
          data: {
            template: require('../../modal-views/entity-custom-note/entity.custom.note.tpl.html'),
            controller: 'EntityCustomNoteController'
          }
        }
      ]);

      var goToLeads = function () {
        $state.go('Leads');
      };

      $scope.editMode = false;
      $scope.detailsMode = 0;
      $scope.toggleDetailsMode = function (mode) {
        $scope.detailsMode = mode;
      };

      $scope.isRightToLeft = localize.isRightToLeft();
      $scope.leadData = [];
      $scope.isLoadingDataWrapper = {};
      $scope.isLoadingCustomerInfo = true;
      $scope.customerTileWrapper = [];
      $scope.isSaving = false;
      $scope.imageIdFromAppToLink = 0;
      $scope.placeHolderLabel = localize.getLocalizedString("_Notes_");
      $scope.customerFieldsToValidate = {}; // just initialize fields to validate.
      $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
      $scope.customerArrivalSourceTextValue = {};

      var originalLead;
      $scope.edit = function () {
        $scope.editMode = !$scope.editMode;
        if ($scope.editMode && !originalLead) {
          originalLead = $.extend(true, {}, $scope.lead);
        }
      };

      $scope.leadDynamic = {};
      $scope.employeeWrapper = {};
      $scope.employees = [
        { name: localize.getLocalizedString("_NoEmployee_"), value: null }
      ];

      $scope.employeeWrapper.SelectedEmployee = $scope.employees[0];
      Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function (employees) {
        $scope.employees.pushAll(employees.results.map(function (employee) {
          return {
            name: employee.FirstName + " " + employee.LastName,
            value: employee.EmployeeId
          };
        }));
      }).catch(function (err) {
        console.log('error loading employees' + err.Message);
      });

      $scope.subStatusWrapper = {};
      $scope.subStatus = [
        { name: localize.getLocalizedString("_None_"), value: null }
      ];
      $scope.subStatusWrapper.SelectedSubStatus = $scope.subStatus[0];
      Repository.Custom("EntitiesLazyRepository").leadSubStatus().get().then(function (statusList) {
        $scope.subStatus.pushAll(statusList.map(function (status) {
          return {
            name: status.name,
            value: status.value
          };
        }));
      }).catch(function (err) {
        console.log('error loading subStatus' + err.Message);
      });


      $scope.imageUpdated = function (entity, image) {
        $scope.imageIdFromAppToLink = image.id;
      };

      $scope.fields = [
        {
          fieldName: 'FirstName',
          icon: "icon icon-clients-ol",
          placeholder: localize.getLocalizedString("_FirstName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'LastName',
          icon: "icon icon-client",
          placeholder: localize.getLocalizedString("_LastName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'MobileFirst',
          customValueTemplate: '<div class="field-with-text-box-value"><span click-call="fieldValue">{{ fieldValue }}</span></div>',
          icon: "icon icon-sms",
          placeholder: localize.getLocalizedString("_Phone_"),
          validation: "{'max-length': 10, 'digits': true}"
        },
        {
          fieldName: 'NextHandlingTime',
          icon: "icon icon-notification-unread",
          placeholder: localize.getLocalizedString("_NextHandlingTime_"),
          type: 'date',
          yearRange: "-0:+2",
          validation: "{'date': true}"
        },
        {
          fieldName: 'EmailAddress',
          icon: "icon icon-mail-outline",
          hideWhenEmpty: true,
          placeholder: localize.getLocalizedString("_Email_"),
          validation: "{'email': true}"
        },
        {
          fieldName: 'Address',
          icon: "icon icon-home-ol",
          placeholder: localize.getLocalizedString("_Address_"),
          validation: "{'max-length': 50}"
        },
        {
          fieldName: 'DateOfBirth',
          icon: "icon icon-gift",
          hideWhenEmpty: true,
          placeholder: localize.getLocalizedString("_DateOfBirth_"),
          type: 'date',
          validation: "{'date': true, 'birthday': true}"
        }
      ];

      $scope.save = function () {
        var validationFields = $scope.validateActionWrapper.validate();
        if (validationFields.length != 0) { // is valid form?
          var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
          var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
          toastr.error(localizedError);
          return;
        }

        $scope.isSaving = true;
        var leadToSave = $.extend({}, $scope.lead);
        delete leadToSave.EntityType;
        delete leadToSave.alwaysExpanded;
        delete leadToSave.isSelected;
        delete leadToSave.$$hashKey;

        if (leadToSave.ArrivalSource) {
          leadToSave.ArrivalSourceId = leadToSave.ArrivalSource.ArrivalSourceId;
          if (leadToSave.ArrivalSource.ArrivalSourceId > 0) {
            delete leadToSave.ArrivalSource;
          }
        }

        leadToSave.LeadSubStatusId = $scope.subStatusWrapper.SelectedSubStatus.value;

        leadToSave.EmployeeId = $scope.employeeWrapper.employee.value;
        delete leadToSave.Employee;

        leadToSave.DateOfBirth = leadToSave.DateOfBirth != null ? moment(leadToSave.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
        leadToSave.NextHandlingTime = leadToSave.NextHandlingTime != null ? moment(leadToSave.NextHandlingTime, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
        $scope.customerArrivalSourceTextValue = {};


        Repository.Entity("Lead").update(leadToSave)
          .post().then(function () {
            loadLead();
            $scope.edit();
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
          })
          .finally(function () {
            $scope.isSaving = false;
            $scope.$digestIfNeeded();
          });
      };

      $scope.cancel = function () {
        $.extend($scope.lead, originalLead);
        $scope.edit();
      };

      // backFilter
      $scope.leftSideBackFilterModel = {};
      $scope.leftSideBackFilterModel.filters = [
        { type: "backButton" }
      ];

      // filter
      $scope.filtersModel = {};
      $scope.filtersModel.filters = [
        { type: "leadDetailsFilter", allowMultiple: true }
      ];

      $scope.referrer = () => {
        let toReturn;
        if ($scope.customerArrivalSourceTextValue) {
          toReturn = $scope.customerArrivalSourceTextValue.text;
        }

        if ($scope.customerArrivalSourceTextValue.referringCustomer) {
          toReturn += ' ' + $scope.customerArrivalSourceTextValue.referringCustomer.FirstName + ' ' + $scope.customerArrivalSourceTextValue.referringCustomer.LastName;
        }

        return toReturn;
      };
      
      $scope.leadDataProxy = Repository.Custom("LeadDetails").data($stateParams.leadId);

      $scope.filtersModel.filterStateChanged = function (newFiltersState) {
        $scope.leadDataProxy.filter(newFiltersState.customerDetailsFilter);
      };

      $scope.tileActionsResolver = {
        deleteEntity: function (entity) {
          var deferred = $q.defer();
          Repository.Entity(entity.EntityType).remove(entity)
            .then(function () {
              toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
              $timeout(goToLeads);
              deferred.resolve(entity);
            })
            .catch(function () {
              toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_"));
              deferred.reject(data);
            });

          return deferred.promise;
        },
        transformLeadToCustomer: function (entity) {
          var deferred = $q.defer();
          Repository.Custom('Leads').transformIntoCustomer(entity.LeadId)
            .then(function (result) {
              toastr.success(localize.getLocalizedString("_TransformedToCustomSuccessfully_"));
              $state.go('CustomerDetails', { customerId: result.CustomerId });
              deferred.resolve(entity);
            })
            .catch(function (err) {
              var message = '';
              try {
                var customerExistMessage = message = err.data['odata.error'].innererror.internalexception.message;
                if (customerExistMessage) {
                  confirmService.confirm(localize.getLocalizedString("_MergeLeadWithCustomer_", message), customerExistMessage,
                    function () {
                      var deferred = $q.defer();
                      Repository.Custom('Leads').mergeIntoCustomer(entity.LeadId)
                        .then(function (result) {
                          toastr.success(localize.getLocalizedString("_TransformedToCustomSuccessfully_"));
                          $state.go('CustomerDetails', { customerId: result.CustomerId });
                          deferred.resolve(entity);
                        })
                        .catch(function (err) {
                          toastr.error(localize.getLocalizedString("_ErrorTransformLead_", ''));
                          deferred.reject(data);
                        });
                    });
                }
              } catch (e) {
              }
              toastr.error(localize.getLocalizedString("_ErrorTransformLead_", message));
              deferred.reject(data);
            })
            .finally(deferred.finally);

          return deferred.promise;
        },
        goToCustomer: function (entity) {
          $state.go('CustomerDetails', { customerId: entity.CustomerId });
        }
      };

      // notes:
      $scope.updateNotes = function () {
        Repository.Entity("Lead").patch($scope.lead.LeadId, { Remarks: $scope.leadNotes.notes })
          .post()
          .then(function () {
            $scope.lead.Remarks = $scope.leadNotes.notes;
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorUpdatingNotes_"));
          });
      };

      loadLead();
      setLabelsForLocale();

      $scope.customerCustomDataPlaceholder = localize.getLocalizedString("_CustomCustomerData_");

      $scope.memberships = [];
      //$scope.punchCards = [];
      $scope.printCustomData = function () {
        printerMediator.print($scope.customerCustomData.data.replace(/\n/g, '<br/>'), true);
        // printerMediator.print('<html><body><div>' + $scope.customerCustomData.data.replace(/\n/g, "<br>") + '</div></body></html>');
      };

      $scope.updateCustomerData = function () {
        Repository.Entity("Customer").patch($scope.lead.CustomerId, { SpecialInformation: $scope.customerCustomData.data })
          .post()
          .then(function () {
            $scope.lead.SpecialInformation = $scope.customerCustomData.data;
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorUpdatingSpecialInformation_"));
          });
      };

      $scope.getPunchcardBalanceAsArray = function (punchCard) {
        return new Array(parseInt(punchCard.TotalQuantity) - parseInt(punchCard.QuantityBalance));
      };

      var dayFilter = $filter('calendarDayShort');
      $scope.getPunchcardFormattedDateOfUsage = function (punchCardUsageDate) {
        return dayFilter(moment(punchCardUsageDate));
      };


      function addCustomLeadNote(timelineEntityCustomNoteToSend) {
        var deferred = $q.defer();
        timelineEntityCustomNoteToSend.EntityType = 'Lead';
        Repository.Custom("CustomTimelineItem").createOrUpdate(timelineEntityCustomNoteToSend)
          .then(function (timelineEntityCustomNote) {
            if (timelineEntityCustomNoteToSend.EntityId) {
              toastr.success(localize.getLocalizedString("_CustomItemUpdatedSuccessfully_"));
            } else {
              toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyCreated_"));
            }

            $scope.$applyIfNeeded(function () {
              $scope.leadData.removeByFunc(timelineEntityCustomNoteToSend,
                function (x) {
                  return x.Id == timelineEntityCustomNoteToSend.EntityId;
                }
                , true /*Allow not exists.*/);
              $scope.leadData.unshift(timelineEntityCustomNote);
            });

            deferred.resolve(timelineEntityCustomNoteToSend);
          }).catch(function (err) {
            toastr.error(localize.getLocalizedString("_ErrorCreatingCutomItem_"), err);
            deferred.reject(err);
          }).finally(function (res) {
            deferred.fulfill(res);
          });

        return deferred.promise;
      }

      function getLeadId() {
        return $stateParams.leadId;
      }

      function loadLead() {
        Repository.Entity("Lead").query().id($stateParams.leadId).expand("Tags,Employee,LeadSubStatus,ArrivalSource").get()
          .then(function (data) {
            if (angular.isDefined(data)) {
              querySucceeded(data);
            } else {
              queryFailed();
            }
          })
          .catch(queryFailed);
      }

      function querySucceeded(data) {
        var updateCurrentCustomer = function () {
          $scope.customerTileWrapper.length = 0;
          $scope.lead = data;
          $scope.lead.alwaysExpanded = true;
          $scope.customerTileWrapper.push($scope.lead);
          $scope.lead.DateOfBirth = $scope.lead.DateOfBirth ? moment($scope.lead.DateOfBirth).format(DATE_DISPLAY_FORMAT) : null;
          $scope.lead.NextHandlingTime = $scope.lead.NextHandlingTime ? moment($scope.lead.NextHandlingTime).format(DATE_DISPLAY_FORMAT) : moment($scope.lead.LastUpdated).format(DATE_DISPLAY_FORMAT);
          $scope.leadNotes = {};
          $scope.leadNotes.notes = data.Remarks;
          $scope.createdOn = localize.getLocalizedString('_Add_') + ': ' + moment($scope.lead.CreatedOn).format(DATE_DISPLAY_FORMAT);
          if (data.Employee) {
            $scope.leadDynamic.SelectedEmployee = {
              name: data.Employee.FirstName + ' ' + data.Employee.LastName,
              value: data.EmployeeId
            };
            $scope.employeeWrapper = {};
            $scope.employeeWrapper.employee = $scope.leadDynamic.SelectedEmployee;
          }
          if (data.LeadSubStatus) {
            $scope.leadDynamic.SelectedSubStatus = {
              name: data.LeadSubStatus.LeadSubStatusName,
              value: data.LeadSubStatus.LeadSubStatusId
            };
            $scope.subStatusWrapper = {};
            $scope.subStatusWrapper.SelectedSubStatus = $scope.leadDynamic.SelectedSubStatus;
          }
          if (data.ArrivalSource) {
            $scope.customerArrivalSourceTextValue.text = data.ArrivalSource.ArrivalSourceName;
          }
        };

        $scope.$applyIfNeeded(updateCurrentCustomer);
        loadLeadInfo();
      }

      function loadLeadInfo() {
        Repository.Custom("LeadDetails").info($stateParams.leadId)
          .then(function (data) {
            $scope.leadInfo = data;
          })
          .catch(function (err) {
            throw (err);
          });
      }

      function queryFailed(/*error*/) {
        toastr.error(localize.getLocalizedString("_EntityWasNotFound_"));
        $timeout(goToLeads, 3000);

      }

      function setLabelsForLocale() {
        $scope.BackScrollName = localize.getLocalizedString("_Back_");
        $scope.DetailsLabelName = localize.getLocalizedString("_CustomerDetails_");
        $scope.InsightsLabelName = localize.getLocalizedString("_Insights_");
        $scope.SortByLabelName = localize.getLocalizedString("_SortBy_");
        $scope.TreatmentsLabelName = localize.getLocalizedString("_Visits_");
        $scope.PromotionsLabelName = localize.getLocalizedString("_AverageInvoice_");
        $scope.EventsLabelName = localize.getLocalizedString("_Events_");
      }

      /********************************** Lead TAGS **********************************/
      $scope.tagsPlaceholder = localize.getLocalizedString('_AddCustomerTag_');
      var tagsAutoCompleteOptions = [];
      Repository.Entity('Tag').query().filter('EntityType eq \'Lead\'').get()
        .then(function (data) {
          if (angular.isDefined(data)) {
            data.value.forEach(function (tag) {
              tag.EntityType = "Lead";
              tagsAutoCompleteOptions.push(tag);
            });
          } else {
            queryFailed();
          }
        })
        .catch(queryFailed);

      $scope.onTagAdded = function (tag) {
        var newTagsAdded = $scope.lead.Tags.filter(function (tagItem) {
          return tagItem.TagId < 0;
        }).length;

        if (!tag.TagId) {
          tag.TagId = -1;
          tag.EntityType = "Lead";
          tag.Color = colorsService.getColorByIndex(tagsAutoCompleteOptions.length + newTagsAdded);
        }
      };

      $scope.loadItems = function (query) {
        var regex = new RegExp('.*' + query + '.*', 'i');
        return tagsAutoCompleteOptions.filter(function (item) {
          return item.Name.match(regex);
        });
      };
      /********************************** ENDOF _ CUSTOMER CHEMISTRY!!! **********************************/

    });
