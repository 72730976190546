<template>
  <div class="px-4">
    <Grid :dataSource="dataSource" v-bind="gridProps" ref="gridEl" @save-preset="savePreset"
      @preset-selected="presetSelected">
      <template v-slot:footer>
        <div>{{ footerText }}</div>
      </template>
    </Grid>
  </div>
</template>

<script>
import { ButtonsGroup, Grid, GridPreset } from "@tomeravni/easybizy-vue-components";
import { LeadsDS } from "./new.leads-ds";
import {
  getSafely,
  isConcreteValue,
  isNonEmptyObject,
  isNumber,
  valueOrDefault,
  xor
} from "@tomeravni/easybizy-js-common/common";
import { toHumanReadablePrice } from "@tomeravni/easybizy-js-common/money";
import { resolveDefaultGridLabels } from "../../vue/vue.helpers";
import { ODATA_DATE_TIME_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";

export default {
  components: {
    Grid,
    ButtonsGroup,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);

    const viewType = 'default';
    const viewTypeOptions = [
      {
        text: this.$t('_Leads_'), value: 'leads'
      }
    ];

    const summaryDictionary = {
      total: this.$t('_TotalLeads_'),
      meetingSet: this.$t('_TotalLeadsSetMeetings_'),
      meetingOccuerred: this.$t('_TotalLeadsWereAtMeetings_'),
      invoiceSet: this.$t('_TotalLeadsGotInvoices_'),
      totalIncome: this.$t('_TotalIncomes_'),
      conversionRate: this.$t('_ConversionRate_'),
      LeadWorth: this.$t('_LeadWorth_'),
    }

    const presets = getSafely(['loggedInUser', 'personalConfigurations', 'presets', 'leads'], this.configurationService, []);
    const adaptedPresets = presets.map((p) => GridPreset.fromJSON(p));
    adaptedPresets.sort((a, b) => a.isDefault ? -1 : 1)
    const selected = presets.find((p) => p.isDefault);

    return {
      viewTypeOptions,
      viewType,
      initSelectedPreset: selected,
      dataSource: null,
      summaryDictionary,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        isPresetsVisible: true,
        labels,
        presets: adaptedPresets,
        selectedPreset: selected?.uid
      },
      footerText: ''
    }
  },
  methods: {
    adaptFooterText(footerData, showTotalIncomes) {
      if (!isNonEmptyObject(footerData) || footerData.HideFooter) {
        return ''
      }
      const { total,meetingOccuerred, meetingSet, invoiceSet, totalIncome, conversionRate, LeadWorth } = this.summaryDictionary;

      let result = `${total}: ${footerData.Leads}, ${meetingOccuerred}: ${footerData.LeadsHadMeetings}, ${meetingSet}: ${footerData.LeadsSetMeetings}, ${invoiceSet}: ${footerData.LeadsGotInvoices}, ${conversionRate}: ${Math.round(footerData.LeadsGotInvoices / (footerData.Leads || 1) * 100)}%`
      if (showTotalIncomes) {
        result += `, ${LeadWorth}: ${toHumanReadablePrice(Math.round((footerData.TotalIncomes ?? 0) / (footerData.Leads || 1)))}`
        result += `, ${totalIncome}: ${toHumanReadablePrice(footerData.TotalIncomes ?? 0)}`
      }
      return result;
    },
    updateDS(viewType) {
      let api;
      let dataSource;
      api = this.Repository.Custom("QueryableTable").leads();
      api.pageSize = 100;
      let scrollToIndex = this.mediator.scrollToItemIndex;

      if (isNumber(scrollToIndex)) {
        this.mediator.onLoadSuccess = (data) => {
          delete this.mediator.onLoadSuccess;
          this.$refs.gridEl.container.scrollTo({
            top: dataSource.layoutManager.getOffset(scrollToIndex - 1),
            behavior: 'smooth'
          });
        };
      }


      dataSource = new LeadsDS(valueOrDefault(scrollToIndex, 0) + 30, api, this.$t, this.$stateManager, this.mediator, this.Repository,
        this.printerMediator, this.$state, this.toastr);
      this.dataSource = dataSource;
      dataSource.setFooter = (data, showTotalIncomes) => {
        this.footerText = this.adaptFooterText(data, showTotalIncomes)
      }

      dataSource.on('modelChange', ({ column, entity, prevValue, newValue, validationErrors }) => {
        // const change = `${column.fieldName}: ${JSON.stringify(newValue)} (Previous: ${JSON.stringify(prevValue)})` +
        //   (validationErrors ? `, errors: ${validationErrors || ''}` : '');

        if (newValue === prevValue || (!isConcreteValue(newValue) && !isConcreteValue(prevValue))) {
          return;
        }

        let adaptedValue = newValue;
        if (column.fieldName === 'NextHandlingTime') {
          adaptedValue = newValue ? moment(newValue, SERVER_DATA_DATE_FORMAT).format(ODATA_DATE_TIME_FORMAT) : null;
        }
        if (newValue === undefined) {
          adaptedValue = null;
        }

        this.Repository.Entity("Lead").patch(entity.LeadId, { [column.fieldName]: adaptedValue }).post().then((res) => {
        }).catch((err) => {
          this.toastr.error(this.$t("_ErrorUpdatingItems_"));
        });
      });

      if (this.initSelectedPreset) {
        dataSource.applyPreset(this.initSelectedPreset)
      }
    },
    savePreset(preset) {
      this.mediator.savePreset(preset, (updatedPresets) => {
        this.updateRawPresets(updatedPresets, preset)
      });
    },
    presetSelected(preset) {
      this.gridProps.selectedPreset = getSafely(['uid'], preset);
      this.dataSource.applyPreset(preset);
    },
    updateRawPresets(rawPresets, preset) {
      const adaptedPresets = rawPresets.map((p) => GridPreset.fromJSON(p));
      adaptedPresets.sort((a, b) => a.isDefault ? -1 : 1)
      this.gridProps.presets = adaptedPresets;
      this.gridProps.selectedPreset = preset?.uid || getSafely([0, 'uid'], adaptedPresets);
    }
  },
  watch: {
    viewType: {
      immediate: true,
      handler(viewType, prev) {
        this.updateDS(viewType);
      }
    },
    mediator: {
      immediate: true,
      handler(mediator, prev) {
        mediator.addItem = (item) => {
          // TODO: DANIEL - item coming back is not the same as other table items.
          this.dataSource.pushItem(item, 0);
        }

        mediator.reloadPresetsDelegate = this.updateRawPresets.bind(this);
      }
    }
  }

}
</script>

<style lang="less">
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter,
.component-fade-leave-to

/* .component-fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>
