import './add.simple.resource.modal.less'

angular.module('easybizy.resources.management').controller('AddSimpleResourceController',
  function ($scope, $modalInstance, saveAction, placeholderName, header, localize, entity, isRoom) {
    $scope.header = header;
    $scope.placeholderName = placeholderName;
    $scope.modelWrapper = { name: entity ? entity.name : '' };

    $scope.isRoom = isRoom;
    if (isRoom) {
      $scope.colorField = {
        fieldName: 'color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_Color_"),
        validation: "{'color': true }", type: 'color'
      };

      $scope.colorWrapper = { color: entity ? entity.color : '' };
      $scope.modelWrapper.maxQuantity = entity?.maxQuantity;
    }

    $scope.save = function () {
      $scope.isSaving = true;
      var entityToUpdate = {
        name: $scope.modelWrapper.name
      };

      if ($scope.isRoom) {
        entityToUpdate.maxQuantity = $scope.modelWrapper.maxQuantity;
        entityToUpdate.color = $scope.colorWrapper.color;
      }

      saveAction(entityToUpdate).then(function () {
        $scope.cancel();
      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_ErrorSavingEntity_'));
      }).finally(function () {
        $scope.isSaving = false;
      })
    };

    Object.defineProperty($scope, 'savable', {
      get: function () {
        return $scope.modelWrapper.name.length > 1 && (!$scope.isRoom || $scope.colorWrapper.color);
      }
    });

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

  });
